<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>

      <div v-if="event">
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-card-title v-if="participant" class="subtitle pt-4">Manage participant <strong class="ml-2">{{ participant.name }}</strong> (id: *{{$route.params.profileId.slice($route.params.profileId.length - 4)}})</v-card-title>
        <v-card-text>      

          <p>
            Use this tool to disconnect a participant from this event.
          </p>

          <v-alert type="info" v-if="participant && participant.verification_code">
            This participant has used the verification code: <strong>{{ participant.verification_code }}</strong>. 
            Disconnecting this participant will release this code.
          </v-alert>
          
          <div v-if="participant && participant.reg">
            <h3>Registration data</h3>
            <p>This participant is linked to registration data.</p>
            Name: <strong>{{ participant.reg.n || '-' }}</strong><br/>
            Given Name: <strong>{{ participant.reg.gn }}</strong><br/>
            Family Name: <strong>{{ participant.reg.fn }}</strong><br/>
            Gender: <strong>{{ participant.reg.ge || '-' }}</strong><br/>
            Race: <strong>{{ participant.reg.r_id || '-' }}</strong><br/>
          </div>
        </v-card-text>
          
        <v-form ref="form" class="mx-4 mb-4">
            <h3>Results</h3>
            <v-switch v-model="deleteResults" persistent-hint hint="When enabled, all results by this particpant will be deleted from the leaderboard." class="mt-0" label="Delete results by this participants"></v-switch>
            <br/>
            <v-snackbar v-model="savedSnackbar" top color="success" :timeout="6000">This particpant has been disconnected from the event!</v-snackbar>
            <v-btn color="red" class="white--text" :loading="$store.getters.isLoading" @click="disconnect">Disconnect participant</v-btn>
        </v-form>
        <br/>
      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue';

export default {
  name: "Profile",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      participant: null,
      profileId: null,
      deleteResults: false,
      savedSnackbar: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async loadData(id, profileId) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.profileId = profileId;

      this.participant = (await eventManagerService.getParticipant(id, profileId)).data;
    },

    async getProfile() {
      if (this.user) {
        await this.loadData(this.$route.params.id, this.$route.params.profileId);
      }
    },

    async disconnect() {
      console.log('Saving');
      var response = (await eventManagerService.disconnectParticipant(this.event.id, this.profileId, this.deleteResults)).data;
      console.log('Saved', response);
      if (response.status == "OK") {
        this.savedSnackbar = true;
        this.$router.push({ name: 'eventmanagerParticipants', params: { id: this.event.id } });
      }
    },

    
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Participants', exact: true, to: { name: 'eventmanagerParticipants', params: {id: this.event.id}} },
        { text: 'Disconnect participant', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

